import styled from "styled-components";

export const PrivacyPolicyPageWrapper = styled.div`
display:flex;
flex-grow:1;
flex-direction:column;
align-items:center;
padding-top:4rem;
background: linear-gradient(144deg, rgba(233,239,251,1) 0%, rgba(133,156,187,1) 100%);
padding-bottom:4rem;
`
