
import { ContactPageWrapper } from "../styles/ContactPage.styled";
import { useState } from "react";
import React from "react";
// import {CgHeart} from 'react-icons/cg'
import {BsHandThumbsUp} from 'react-icons/bs'
import { BarLoader } from "react-spinners";
import {BsWhatsapp} from 'react-icons/bs'
import {BsFillTelephoneFill} from 'react-icons/bs'

import ContactForm from '../components/ContactForm'

function ContactPage({error, setError}) 
{
    const [thankYou, setThankYou] = useState(false);
    const [loading, setLoading] = useState(false)
   

    return (
    <ContactPageWrapper>
        {
            !loading ? 
            (
                !thankYou ? 
                (
                    <ContactForm setThankYou={setThankYou} setLoading={setLoading} loading={loading} setError={setError} error={error}/>
                )
                :
                (
                <>
                    <div style={{color:'white', width:'100%', height:'100%', background: 'linear-gradient(55deg, rgba(65,105,225,1) 0%, rgba(37,190,154,1) 100%)', display:'flex', flexDirection:'column' ,alignItems:'center', justifyContent:'center'}}>    
                        <div style={{textAlign:'center', transform: 'translateY(-50px)', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                            <BsHandThumbsUp color="white" size={'6rem'}/>
                            <h1 style={{marginBottom:'25px', marginTop:'25px'}}>Thank you for your enquiry.<br></br>We will be in contact with you shortly to process your claim.</h1>
                            {/* <h2>Please make a note of our contact details:</h2>
                            <div style={{display:'flex', marginTop:'50px', alignItems:'center', gap:'25px'}}>
                                <BsFillTelephoneFill size={40}/>
                                <h2 style={{ fontSize:'2.5rem'}}></h2>
                            </div>
                            <div style={{display:'flex', alignItems:'center', gap:'25px'}}>
                                <BsWhatsapp size={50}/>
                                <h2 style={{ fontSize:'2.5rem'}}></h2>
                            </div> */}
                        </div>
                    </div>
                </>
                )
            )
            :
            (
                <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:'50px'}}>
                    <h1>Please bear with us whilst we process your enquiry, this might take a minute.</h1>
                    <BarLoader color="royalblue" loading={true} width='20%'/>
                </div>
            )
                
                
            
                
            
            
        }
        
    </ContactPageWrapper>
  );
}

export default ContactPage;
