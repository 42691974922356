import styled from "styled-components";

export const ContactPageWrapper = styled.div`
width:100%;
display:flex;
flex-grow:1;
justify-content:flex-start;
flex-direction:column;
align-items:center;
// background: linear-gradient(144deg, rgba(233,239,251,1) 0%, rgba(133,156,187,1) 100%);

@media (max-width: 1200px) 
{
    box-sizing:border-box;
   
    padding-left:0rem;
    padding-right:0rem;
   
}

`