import React from "react";
import { PrivacyPolicyPageWrapper } from "../styles/PrivacyPolicy.styled";
import { LegalHeading } from "../styles/TermsOfUse.styled";
import { Paper } from "@mui/material";
import {Button} from "@mui/material";

function PrivacyPage() 
{
  
    const scroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

  return (
   <PrivacyPolicyPageWrapper className="legalWrapper">
     <Paper className="legalWrapperInner" sx={{width:'80%', display:'flex', flexDirection:'column', gap:'50px', padding:'4rem'}} elevation={3}>
        <h1 style={{textAlign:'center', color:'royalblue', textDecoration:'underline'}}>Privacy Policy</h1>
        <div className="center" style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <h3 className="legalStartText" style={{width:'80%', textAlign:'center', color:'royalblue'}}>
                    The use of this Website is subject to the terms and conditions set out below. 
                    Please take a moment to read these terms and conditions carefully. By using or accessing any of this Website, you agree to be bound by the following terms and conditions
            </h3>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">WHY WE REQUIRE YOUR INFORMATION</LegalHeading>
            <p>
                We may collect personally identifiable information when you register with us and when you use our services. We ask you for personal data only when it is needed to provide the services you have enquired about or asked us to provide or to respond to your requests for information. You do not need to provide us with personal data simply to browse our site except on sections of the site where we have asked you to register with us.
                We use this information to customise the content you see on our pages, to fulfil your request for certain information and services, to communicate with you generally and to contact you about new services, updates and events.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">WHAT WE USE YOUR INFORMATION FOR</LegalHeading>
            <p>
                Any information we collect will be used to enable us to carry out our business of providing or facilitating the Services offered on this website.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">WHAT INFORMATION DO WE COLLECT?</LegalHeading>
            <p>
            We will collect information from you in the following circumstances:
            - Registration/Enquiries
            As part of the registration or enquiry process we will collect, use and retain details such as your name, address, telephone number, email address and other information needed to process your registration or enquiry.
            Some areas of our web site may require you to be registered with us prior to certain information or services being made available. We use this information to provide you with the service or information you have requested or to answer your queries.
            - Optional Information
            We may request optional information from you on our registration forms for example on your personal and business interests. We request this optional information to provide more tailored products and services to you and so that we may understand our users better (e.g. through internal research, data analysis, market research and product research). If we request this additional information from you we will identify it as optional.
            - Information from Other Sources
            In the normal course of our business we may check some of the information you provide to us against third party databases to confirm that it is accurate.
            - Cookies
            Some areas of our website may use cookies so that we can serve you better. For example, we may set cookies to maintain the open menus as you move between pages. These are used only when you are on the web site. You can refuse cookies by turning them off on your web browser and you do not need to have cookies turned on to successfully browse the site, but you may not be able to use certain parts of the website without them.
            - Website Usage Data
            With or without cookies, our website keeps track of usage data, such as the source address that the page request is coming from (i.e. your IP address, domain name), date and time of the page request, the referring website (if any) and other parameters in the URL (e.g. search criteria). We use this data to better understand website usage so we know what areas of our websites users prefer. This information is stored in log files and is used for aggregated and statistical reporting, but is not attributed to you as an individual.
            - Security
            We use security safeguards to protect information from unauthorised access and misuse. We use the most accurate and current information you provide in order to process your request.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">WHAT INFORMATION DO WE SHARE?</LegalHeading>
            <p>
                There are times when we may be required to share the information we learn from you with other parties. We will send personally identifiable information about you to other companies or people in the following situations:-
                - Third Parties
                We may share your information with professional advisors, associated companies and other relevant financial and non-financial institutions for the purposes of providing you with the products and or services about which you have enquired. We may also use professional agents to provide marketing assistance, data analysis, handling credit card transactions and following up on enquiries (including by phone, e-mail, text, social media, post or fax). These agents have access to the personal information required in order to perform their functions, but may not use it for any other purpose.
                - Compliance with law
                We will release personal information where we are required to do so by law or by any regulations and other rules (including professional and auditing requirements) to which we are subject.
                - Consent
                We will only share your information whee you have given us your consent to do so.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">THE PROTECTION OF CHILDREN</LegalHeading>
            <p>
                It is our intention that our services only be provided to persons over the age of 16. We do not knowingly collect personal 
                data of persons under the age of 16 without the appropriate parental consent.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">MERGERS, ACQUISITIONS, SALES ETC</LegalHeading>
            <p> 
                If this business is sold or integrated with another business, your details may be disclosed to our advisors, 
                any prospective purchasers and their advisors and will be passed on to the owners of the business.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">ACCESS TO INFORMATION UNDER THE DATA PROTECTION ACT 2018</LegalHeading>
            <p>
                You can find out what information we keep about you by requesting a copy of that information. We will provide this to you within 40 days of your written request (if not earlier). There is no fee. To maintain security we may require proof of your identity. We allow you to challenge the data that we hold about you and, where appropriate, you may request that data we hold be corrected or deleted, as necessary.
            </p>
        </div>
        <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
            <LegalHeading className="center">CHANGES TO PRIVACY POLICY</LegalHeading>
            <p>
                We may edit this policy from time to time. If we make any substantial changes we will notify you by posting a prominent announcement on our web pages
            </p>
        </div>
        <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Button style={{width:'fit-content'}} onClick={() => scroll()} variant="contained">Go to top of page</Button>
        </div>
        </Paper>
        
        
   </PrivacyPolicyPageWrapper>
  );
}

export default PrivacyPage;
