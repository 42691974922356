import { Button, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClaimLeft, ClaimRight, HowWeCanHelp, StreetImage } from "../styles/HomePage.styled";
import { HomePageWrapper, StreetImageHeader, HelpWrapper } from "../styles/HomePage.styled";
import React from "react";
import { Requirements, HelpTextSection, YouCanClaim, ClaimWrapper, ClaimSection, RightHeader, ClaimRightInnerWrapper, YourLandLordMust } from "../styles/HomePage.styled";
import {TbHandFinger} from 'react-icons/tb'
import {GiBrokenWall} from 'react-icons/gi'
import {GoLaw} from 'react-icons/go'

function HomePage() 
{
  const navigate = useNavigate();
  
  return (
    <HomePageWrapper>
       <StreetImage>
          <Card sx={{display:'flex', flexDirection:'column', textAlign:'center', padding:'4rem', backgroundColor:'rgba(0, 0, 0, 0.5)', fontSize:'33px', borderRadius:'1rem'}}>
          <StreetImageHeader>We fight for <span style={{color:'rgba(37, 190, 154, 1) !important', display:'inline'}}><b style={{color:'royalblue', display:'inline-block', marginTop:'-13px', textDecoration:'underline'}}>your</b></span> rights.</StreetImageHeader>
          <StreetImageHeader> <span style={{color:'rgba(37, 190, 154, 1) !important', display:'inline'}}><b style={{color:'#25be9a', display:'inline-block', marginTop:'-13px', textDecoration:'underline'}}>Enquire today</b></span> to find<br></br> out if you are eligable<br></br>for <span style={{color:'rgba(37, 190, 154, 1) !important', display:'inline'}}><b style={{color:'royalblue', display:'inline-block', marginTop:'-13px', textDecoration:'underline'}}>compensation.</b></span></StreetImageHeader> 
            <Button className="button pulse" sx={{'&:hover': {border: '0px solid white !important', borderColor: 'lightgrey !important', backgroundColor:'rgba(37, 190, 154, 1) !important'}, marginTop:'50px' ,color: '#ffffff !important', borderRadius:'1rem' ,border: '0px solid white !important', backgroundColor:'rgba(65, 105, 225,1) !important', borderColor:'#ffffff !important', fontWeight:'800 !important', fontSize:'1.25rem', animation: 'pulse 1.5s infinite'}} style={{'filter': 'drop-shadow(rgba(0, 0, 0, 1) 3px 3px 2px)'}} variant="outlined" onClick={() => navigate('/Contact')}>CLAIM NOW</Button>
          </Card>
      </StreetImage>
      <Requirements style={{ paddingBottom:'50px'}}>
       <ClaimWrapper style={{minHeight:'700px', display:'flex', flexDirection:'column', gap:'50px', justifyItems:'flex-start'}}> 
        <h1 className="center" style={{textDecoration:'underline', color:'royalblue', marginTop:'50px', filter:'drop-shadow(rgba(0, 0, 0, 0.3) 3px 3px 2px)'}}>What is a Housing Disrepair claim?</h1>
          <div className="whatIsHousingDisrepair" style={{display:'flex', gap:'50px', fontSize:'1.5rem'}}>
            <div className="makeBigger" style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center', width:'50%', gap:'25px'}}>
              <GiBrokenWall size={75} color={'rgb(37, 190, 154)'}/>
              <p style={{width:'100%'}}>A property is said to be in a state of disrepair if the Landlord has failed to make good the necessary repairs in order to make the property safe and suitable for occupation by the tenant.</p>
            </div>

            <div className="makeBigger" style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center', width:'50%', gap:'25px'}}>
              <GoLaw size={75} color={'rgb(37, 190, 154)'}/>
              <p style={{width:'100%'}}>Your Landlord has certain legal responsibilities to keep the property in a good state of repair. The property also needs to be fit for living in.</p>
            </div> 
        </div>
        <p className="center" style={{fontSize:'1.5rem'}}>We’ve all seen horror stories in the news and on TV where tenants have been living with mould, lack of heating, leaks, damp, poor electrical wiring and much more. These things and many other issues like these are the responsibility of your landlord and they have to deal with them. Our housing disrepair team can help you get compensation.</p>
        </ClaimWrapper>
       </Requirements>
      <YourLandLordMust style={{display:'flex', justifyContent:'flex-start', paddingTop:'50px'}}>
      <ClaimWrapper style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', color:'white', paddingLeft:'50px', paddingRight:'50px'}}> 
        <h1 className="center" style={{textDecoration:'underline', marginBottom:'25px', color:'white', filter:'drop-shadow(rgba(0, 0, 0, 0.3) 3px 3px 2px)'}}>Your Landlord must do these things</h1>
        <p className="center" style={{textDecoration:'none !important', display:'inline-block', color:'white', fontSize: '18.4px', marginBottom:'50px'}}>Not only does your landlord have to do everything your tenancy agreement says, but they are also usually responsible for things like:</p>
        
        <div style={{display:'flex', flexWrap:'wrap', marginTop:'25px'}}>
          <ClaimSection style={{width:'50%', paddingRight:'10px', alignItems:'center'}}>
            <img alt="Leaks" style={{width:'100px', height:'100px'}} src="./img/icon_toilet_green.svg"/>
            <p>Toilets, sinks, baths and all the pipework associated with them</p>
          </ClaimSection>
          <ClaimSection style={{width:'50%', paddingRight:'10px', alignItems:'center'}}>
            <img alt="windows" style={{width:'100px', height:'100px'}} src="./img/icon_electrics_green.svg"/>
            <p>Electrical wiring, electric fires and heaters</p>
          </ClaimSection>
          <ClaimSection style={{width:'50%', paddingRight:'10px', alignItems:'center'}}>
            <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_boiler_green.svg"/>
            <p>Water tanks, boilers and radiators</p>
          </ClaimSection>
          <ClaimSection style={{width:'50%', paddingRight:'10px', alignItems:'center'}}>
            <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_window_green.svg"/>
            <p>Windows and external doors</p>
          </ClaimSection>

        </div>
       
    
        {/* <h1 style={{textDecoration:'underline', marginBottom:'25px', color:'white'}}>Your landlord cannot remove these responsibilities form your tenancy, even if your tenancy says they do</h1>
    
      <ClaimSection style={{width:'100%'}}>
        <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_fungus.svg"/>
        <p>Your landlord cannot pass on the cost of any repair work which is their responsibility</p>
      </ClaimSection>
      <ClaimSection style={{width:'100%'}}>
        <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_fungus.svg"/>
        <p>Your Landlord cannot expect you to make these repairs</p>
      </ClaimSection>
      <ClaimSection style={{width:'100%'}}>
        <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_fungus.svg"/>
        <p>Your Landlord only has to make repairs when they know there is a problem</p>
      </ClaimSection>
           
           
            <p>Your Landlord only has to make repairs when they know there is a problem</p> */}
        </ClaimWrapper>
       </YourLandLordMust>
       <Requirements>
       <ClaimWrapper> 
            <ClaimRightInnerWrapper > 
            <ClaimRight style={{flexDirection:'column', width:'100%'}}>
              <RightHeader>
                <h1 style={{textDecoration:'underline', marginBottom:'25px', filter:'drop-shadow(rgba(0, 0, 0, 0.3) 3px 3px 2px)'}}>How can our team help?</h1>
                <p style={{textDecoration:'none !important', display:'inline-block'}}>In order to make a claim for Housing Disrepair, you need to be able to say yes to the following</p>
              </RightHeader>
                <ClaimSection style={{width:'100%'}}>
                  <img alt="Leaks" style={{width:'100px', height:'100px'}} src="./img/icon_reportit.svg"/>
                  <p>You reported the problem to the your Council or Housing Association</p>
                </ClaimSection>
                <ClaimSection style={{width:'100%'}}>
                  <img alt="windows" style={{width:'100px', height:'100px'}} src="./img/icon_failed.svg"/>
                  <p>Your Council or Housing Association must have failed to perform the repair</p>
                </ClaimSection>
                <ClaimSection style={{width:'100%'}}>
                  <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_tenant.svg"/>
                  <p>The problem must not be caused by you (the Tenant)</p>
                </ClaimSection>
            </ClaimRight>
           </ClaimRightInnerWrapper>
           <ClaimLeft style={{display:'flex', justifyContent:'flex-end', marginLeft:'50px'}}>
              <img alt="you can claim"  src="./img/requirement.svg"/>
            </ClaimLeft>
        </ClaimWrapper>
       </Requirements>
       <HowWeCanHelp>
        <HelpWrapper>
          <HelpTextSection>
              <TbHandFinger color="white" size={60} style={{transform: 'rotate(90deg)', minWidth:'60px', minHeight:'60px'}}/>
              <p>No win no fee with no risk to you</p>
            </HelpTextSection>
            <HelpTextSection>
              <TbHandFinger color="white" size={60} style={{transform: 'rotate(90deg)', minWidth:'60px', minHeight:'60px'}}/>
              <p>We Arrange Repairs and get you Compensation</p>
            </HelpTextSection>
            <HelpTextSection>
              <TbHandFinger color="white" size={60} style={{transform: 'rotate(90deg)', minWidth:'60px', minHeight:'60px'}}/>
              <p>We do everything on your behalf</p>
            </HelpTextSection>
            <HelpTextSection>
              <TbHandFinger color="white" size={60} style={{transform: 'rotate(90deg)', minWidth:'60px', minHeight:'60px'}}/>
              <p>The repairs are carried out to a high standard</p>
            </HelpTextSection>
        </HelpWrapper>
            <Button className="button2" sx={{'&:hover': {border: '0px solid white !important', borderColor: 'lightgrey !important', backgroundColor:' rgba(37, 190, 154,0.8 ) !important'}, width:'400px' ,marginTop:'50px' ,color: '#ffffff !important', borderRadius:'1rem' ,border: '0px solid white !important', backgroundColor:' rgba(37, 190, 154,1 ) !important', borderColor:'#ffffff !important', paddingLeft:'2rem !important', paddingRight:'2rem !important', paddingTop:'1rem !important', paddingBottom:'1rem !important', fontWeight:'800 !important'}} style={{'filter': 'drop-shadow(rgba(0, 0, 0, 0.3) 3px 3px 2px)'}} variant="outlined" onClick={() => navigate('/Contact')}>CONTACT US</Button>
       </HowWeCanHelp>
       <YouCanClaim>
            
          <ClaimWrapper className="reversed">
            
            <ClaimLeft>
              <img alt="you can claim"  src="./img/youcanclaim.svg"/>
            </ClaimLeft>
            <div style={{width:'100%'}}> 
            <ClaimRight className="needsColumn">
            <RightHeader>
                <h1 style={{filter:'drop-shadow(rgba(0, 0, 0, 0.3) 3px 3px 2px)'}}>You can claim the following</h1>
              </RightHeader>
                <ClaimSection>
                  <img alt="defective boiler" style={{width:'100px', height:'100px'}} src="./img/icon_boiler.svg"/>
                  <p>Defective Boiler</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="Leaks" style={{width:'100px', height:'100px'}} src="./img/icon_leaks.svg"/>
                  <p>Leaks or Water damage</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="cracks" style={{width:'100px', height:'100px'}} src="./img/icon_cracks.svg"/>
                  <p>Cracks</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="windows" style={{width:'100px', height:'100px'}} src="./img/icon_window.svg"/>
                  <p>Defective windows/doors</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="dangerous electronics" style={{width:'100px', height:'100px'}} src="./img/icon_electrics.svg"/>
                  <p>Defective or Dangerous Electrics</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="structural damage" style={{width:'100px', height:'100px'}} src="./img/icon_wall.svg"/>
                  <p>Internal/External structural damage</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="mould, damp and fungus" style={{width:'100px', height:'100px'}} src="./img/icon_fungus.svg"/>
                  <p>Mould/fungus or damp</p>
                </ClaimSection>
                <ClaimSection>
                  <img alt="insect or mice infestation" style={{width:'100px', height:'100px'}} src="./img/icon_insects.svg"/>
                  <p>Insects/Mouse Infestation</p>
                </ClaimSection>
                
            </ClaimRight>
           </div>
          </ClaimWrapper>
       </YouCanClaim>
    </HomePageWrapper>
  );
}

export default HomePage;
