import styled from 'styled-components'

export const HeaderWrapper = styled.header`
    width:100%;
    min-height:170px;
    display:flex;
    align-items:center;
    justify-content:space-around;
    box-sizing:border-box;
    padding:0;
    margin:0;   
    box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    z-index:10;

    @media only screen and (max-width: 600px) {
        
            flex-direction: column;
            padding-top:25px;
            & > img {
                width:75vw;
                height:20vw !important;
            }
      }
`

export const NavWrapper = styled.nav`
   
    height:150px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    gap:5px;
    @media only screen and (max-width: 550px) {
        
        height:0px;
        margin-bottom: 1rem;

      }

`

export const NavButton = styled.button`
    width:fit-content;
    border:1px solid black;
    height:50px;
    padding-left:2rem;
    padding-right:2rem;
    background-color:transparent;
`

export const PhoneNumber = styled.p`
font-weight:800;
font-size:1.25rem;
color: royalblue;
text-decoration:underline;
display:flex; 
align-items:center;
gap:5px;
`

export const HeaderText = styled.p`
font-weight:800;
color: #25BE9A
`