import { HeaderWrapper, NavWrapper } from "../styles/Header.styled";
import Button from '@mui/material/Button';
import React from "react";
import { ButtonGroup} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PhoneNumber, HeaderText } from "../styles/Header.styled";
import {BsFillTelephoneFill} from 'react-icons/bs'
import {RiWhatsappFill} from 'react-icons/ri'

function Header() 
{
   const navigate = useNavigate();



    return (
        <HeaderWrapper>
            
            <img onClick={() => navigate('/')} alt="Housing disrepair" src="./img/HDRClaimsLogo.svg" style={{height:'100px', cursor:'pointer'}}/>
            <NavWrapper>
                {/* <HeaderText>Give us a call and whatsapp us at</HeaderText> */}
                {/* <PhoneNumber><BsFillTelephoneFill color="green"/></PhoneNumber>
                <PhoneNumber><RiWhatsappFill size={25} color="green"/></PhoneNumber> */}
                <ButtonGroup>
                    <Button sx={{paddingX:'2rem', borderColor:'royalblue', color:'royalblue'}} onClick={() => navigate('/')}>Home</Button>
                    <Button sx={{paddingX:'2rem', borderColor:'royalblue', color:'royalblue'}} onClick={() => navigate('/Contact')}>Enquire now</Button>
                </ButtonGroup>
            </NavWrapper>
          
          
        </HeaderWrapper>
    );
}

export default Header;
