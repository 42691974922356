import { Button, Paper } from "@mui/material";
import React from "react";
import { TermsOfUsePageWrapper } from "../styles/TermsOfUse.styled";
import { LegalHeading } from "../styles/TermsOfUse.styled";

function TermsOfUsePage() 
{
  
    const scroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

  return (
    <TermsOfUsePageWrapper className="legalWrapper">
        <Paper className="legalWrapperInner" sx={{width:'80%', display:'flex', flexDirection:'column', gap:'50px', padding:'4rem'}} elevation={3}>
            <h1 style={{textAlign:'center', color:'royalblue', textDecoration:'underline'}}>Terms of use</h1>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <h3 className="legalStartText" style={{width:'80%', textAlign:'center', color:'royalblue'}}>
                    The use of this Website is subject to the terms and conditions set out below. 
                    Please take a moment to read these terms and conditions carefully. By using or accessing any of this Website, you agree to be bound by the following terms and conditions
                </h3>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">ABOUT US</LegalHeading>
                <p>
                    We are How? Media Ltd, Woodcote House, Grove Road, Basingstoke, RG21 3AX (“the Company”, “we”, “us”, “our”).
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">COPYRIGHT</LegalHeading>
                <p>
                    The copyright and material on this website is owned by us and is protected by the copyright laws of the United Kingdom. 
                    The material on this website may only be used by those who access it from the United Kingdom and such users will be entitled to 
                    copy any such information for their own personal use, but may not republish, store or reproduce any such information in any manner, 
                    including but not limited to electronic reproduction, without the prior written consent of the Company. Any unauthorised downloading,
                    retransmission, other copying or modification of any of the contents of this website may be in breach of statutory or common law rights
                    which could be the subject of legal action. The Company disclaims all liability which may result from any unauthorised reproduction or use of the information on this website.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">TRADE MARK</LegalHeading>
                <p>
                    Certain names, words, titles, phrases, logos, icons, graphics or designs to the pages of this website may constitute trade names or unregistered trademarks of the Company. 
                    The display of any of the above trade names or trademarks on pages of this website does not imply that any licence has been granted to any third party in respect of the same. 
                    All other product or company names and devices, logos, icons, graphics or designs referred to on the pages of this website are the trademarks of their respective owners and are
                    exhibited only in such a manner as is intended to be for the benefit of such trade mark owners and the Company intends no infringement of such trademarks. The appearance or absence
                    of products, services, companies, organisations, home pages or other Websites on this website does not imply any endorsement or non-endorsement thereof by the Company. All rights
                    not expressly granted herein are reserved by the Company.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">LIABILITY</LegalHeading>
                <p>
                    We shall not be liable for any direct or indirect, incidental or consequential damages, including the loss of profits, business revenue or goodwill arising from the use or access of
                    or inability to use or access, interruption or availability of this website, its operation or transmission, computer viruses, loss of data or otherwise in respect of its use.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">WARRANTIES</LegalHeading>
                <p>
                    The information, materials and functions contained in this website including text, graphics, links or other items are provided on an "as is" basis excluding any warranty whatsoever, 
                    express or implied, including but not limited to warranties of satisfactory quality, fitness for a particular purpose or freedom from computer viruses. The Company excludes any liability 
                    for any errors in or omissions from information, materials and functions included in this website or any third party websites linked to or from this website.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">JURISDICTION</LegalHeading>
                <p> 
                    This website is intended to be accessed only by persons located in the UK We  make no representation that any information, materials or functions included in this website are appropriate for 
                    use in any other jurisdiction. However, if you are unsure whether the products and services referred to on this website are available to you, please write to us at the address above.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">PERSONAL DATA &amp; PRIVACY POLICY</LegalHeading>
                <p>
                    As the Internet is not a secure medium of communication, we cannot guarantee the security of any information you input on this website or send to us on the Internet. We are not, and will not be, responsible for any damages you or others may suffer as a result of the loss of confidentiality of any such information.
                    To minimise the possible risks associated with the provision of personal data, every on-line application on this website is encrypted using a minimum of 128-bit encryption. This ensures information is virtually impossible for anyone else to read unless they have the 'key' or password. A secure communications link, called a Secure Socket Layer (SSL), encrypts all the information passed between your browser and our online applications. Pages protected by SSL will be visibly prefixed by HTTPS:// at the beginning of the page web address.
                    By agreeing to these terms and conditions, and where you have given appropriate consents, we may contact you about services or products offered by us or other companies in our group or approved by us which we believe you may be interested in, or to carry out market research about our services or products or those of third parties. We may also pass information to other companies approved by us so that they may contact you about services or products which they believe you may be interested in.
                    Contact for these purposes may be by post, email, SMS, automated voice or by other means as we may agree with you from time to time. This will override any registrations you may have with any preference services.
                    If you do not wish to receive on-going contact from us please contact us at the address above.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">WEB LINKS</LegalHeading>
                <p>
                    The provision by us of a link to another website does not constitute any authorisation by us to the user to access material held at that location, nor is it evidence of any endorsement by us of the 
                    material held there. We accept no responsibility or liability for the privacy of your personal information on such websites, as these are beyond our control. The Company accepts no responsibility or 
                    liability in respect of any materials on any website which is not under its control.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">WEBSITE PROBLEMS</LegalHeading>
                <p>
                    This website has been extensively tested and should work properly at all times. However, if you experience a problem at any time, please return to the homepage and try again. If the problem persists 
                    you can contact us by using the Contact Us page or link.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">ENFORCEABILITY</LegalHeading>
                <p>
                    In the event any provision of these terms and conditions of use and access to this website is held unenforceable, it will not affect the validity and enforceability of the remaining provisions 
                    and will be replaced by an enforceable provision that comes the closest to the intention underlying the unenforceable provision.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">INFORMATION</LegalHeading>
                <p>
                    Information provided in this website is believed to be reliable when posted. However, we cannot guarantee that information will be accurate, complete and current at all times. All information on 
                    this website is subject to modification from time to time without notice.
                </p>
            </div>
            <div className="center" style={{display:'flex', gap:'15px', flexDirection:'column'}}>
                <LegalHeading className="center">GOVERNING LAWS</LegalHeading>
                <p>
                    The terms and conditions of use of this website shall be governed by and construed in accordance with the laws of England and Wales.
                </p>
            </div>
            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Button style={{width:'fit-content'}} onClick={() => scroll()} variant="contained">Go to top of page</Button>
            </div>
        </Paper>
    </TermsOfUsePageWrapper>
  );
}

export default TermsOfUsePage;
