import { FooterWrapper } from "../styles/Footer.styled";
import { useNavigate } from "react-router-dom";

function Footer() 
{
    const navigate = useNavigate();
  


    return (
        <FooterWrapper>
           
            <div style={{display:'flex', gap:'5px', justifyContent:'center', display:'flex', flexDirection:'column', alignItems:'center'}}>
                
                <div style={{display:'flex'}}>
                    <div onClick={() => navigate('/privacypolicy')}>
                        <p style={{textAlign:'center', cursor:'pointer'}}>
                            <b>Privacy policy </b>
                        </p>
                    </div>
                        <p style={{textAlign:'center'}}>
                            <b> &nbsp; | &nbsp; </b>
                        </p>
                    <div onClick={() => navigate('/termsofuse')}>
                        <p style={{textAlign:'center', cursor:'pointer'}}>
                            <b> Terms of use </b>
                        </p>
                    </div>
                </div>
                <p>HDRClaims.com is owned and operated by How? Media Ltd registered In England &amp; Wales. Registration Number 08674733. ICO Registration Number: ZA020080</p>
                <p style={{width:'60%', fontSize:'0.7rem', textAlign:'center'}}>Please remember that How? Media Ltd is a resource and introducer only and is not regulated nor can guarantee the information shown within the content of this website is always correct.</p>
            </div>
        </FooterWrapper>
    );
}

export default Footer;
