import styled from "styled-components";

export const FooterWrapper = styled.footer`
    width:100%;
    padding:2rem;
    background-color:royalblue;
    color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:25px;
    box-shadow:
    10px 10px 10px 10px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), -5px 5px 5px 5px rgb(0 0 0 / 12%);
    z-index:10;


    @media (max-width: 1200px) 
    {
    text-align:center;
    
    }
`