import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TermsOfUsePage from "./pages/TermsOfUse";
import PrivacyPage from "./pages/PrivacyPolicy";
import { useState } from "react";

function App()
{
  const [error, setError] = useState(false)

  return (
    <>
      <Header/>
        <Routes>
          <Route default exact path='/' element={<HomePage/>} />
          <Route path='/Contact' element={<ContactPage error={error} setError={setError}/>} />
          <Route path='/PrivacyPolicy' element={<PrivacyPage/>} />
          <Route path='/TermsOfUse' element={<TermsOfUsePage/>} />
          <Route path='*' element={<Navigate to="/" />}/>
        </Routes>
      <Footer/>
    </>
  );
}

export default App;
