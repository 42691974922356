import styled from "styled-components";

export const HomePageWrapper = styled.div`
    width:100%;
    display:flex;
    flex-grow:1;
    background: linear-gradient(55deg, rgba(236,239,246,0) 0%, rgba(227,231,230,1) 100%);
    flex-direction:column;
    
`

export const ClaimRightInnerWrapper = styled.div`
max-width:1100px;

@media (max-width: 1200px) 
{
    padding-left:0rem;
    padding-right:0rem;
  }

`

export const StreetImage = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    background-image:linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3)
    ),url(./img/streetbackgroundsmaller.webp);
    width:100%;
    background-position:50% 60%;
    background-repeat:no-repeat;
    background-size: cover;
    flex-direction:column;
    gap:25px;
    padding-left:2rem;
    padding-right:2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;


    @media (max-width: 550px) 
    {
        padding-top: 1rem;    
        padding-bottom: 1rem;

        & > div 
        {
            font-size:clamp(0.5rem, 5vw, 2rem);
            padding-left:0.5rem;
            padding-right:0.5rem;  
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    } 
    `


export const Btnwrp = styled.div`
& > button {
    padding:0 !important;
}
`

export const StreetImageHeader = styled.h1`
font-size:3rem;
filter: drop-shadow(rgba(0, 0, 0, 1) 3px 3px 2px);
color:${props => props.color ? props.color : "white"};
@media (max-width: 450px) 
    {   
            font-size:clamp(0.8rem, 7.5vw, 3rem);
    } 
`

export const Requirements = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
`

export const HowWeCanHelp = styled.div`
    width:100%;
    height:700px;
    display:flex;
    flex-direction:column;
    gap:25px;
    background-color:royalblue;
    align-items:center;
    justify-content:center;
    padding:4rem;

    @media (max-width: 550px) 
    {   
            padding-left:2rem;
            padding-right:2rem;
    } 
`

export const HelpTextSection = styled.div`
    display:flex;
    align-items:center;
    gap:15px;
    color:white;
    font-weight:800;
    font-size:1.75rem;

    @media (max-width: 550px) 
    {
            font-size:clamp(0.5rem, 5vw, 2rem);
            padding-left:0.0rem;
            padding-right:0.0rem;
    } 
`

export const HelpWrapper = styled.div`
display:flex;
flex-direction: column;
gap:25px;

`
export const YouCanClaim = styled.div`
width:100%;
flex-grow:1;
display:flex;
justify-content:center;
`

export const ClaimWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
padding-bottom:25px;
max-width:1100px;
width:100%;
@media (max-width: 1200px) 
{
    flex-direction: column;
   
    padding-left:4rem;
    padding-right:4rem;
   
}


`

export const ClaimLeft = styled.div`
display:flex;
flex-wrap:wrap;
width:400px;
margin-right:75px;

@media (max-width: 1200px) 
{
    margin-right:0px;
    margin-left:0px !important;
    justify-content: center !important;
    margin:0 !important;
    width:auto;
        padding:0;
    & > img {
        margin:0;
        padding:0;
        height:60vw;
        width:60w;
        max-width:250px;
    }
  }

    
 


`
export const ClaimRight = styled.div`
        display:flex;
        flex-wrap:wrap;
        //width:700px;
        max-width:1100px;
        align-items:flex-start;
        jusify-content:flex-start;
        width:100%;
        
`

export const ClaimSection = styled.div`
        display: flex;
        align-items:center;
        height:fit-content;
        gap:25px;
        width:50%;
        margin-bottom:45px;
        font-size:1.5rem;
        
        & > path {
            box-shadow:
            inset 2.8px 2.8px 1.8px rgba(0, 0, 0, 0.07),
            6.7px 6.7px 4.3px rgba(0, 0, 0, 0.05),
            12.5px 12.5px 8px rgba(0, 0, 0, 0.042),
            22.3px 22.3px 14.3px rgba(0, 0, 0, 0.035),
            41.8px 41.8px 26.7px rgba(0, 0, 0, 0.028),
            100px 100px 64px rgba(0, 0, 0, 0.02)
            ;
        }

        @media (max-width: 835px) 
        {
            flex-direction:column;
            width:100% !important;
           text-align:center;
        }
`

export const RightHeader = styled.div`
    width:100%;
    justify-content:center;
    display:flex;
    align-items:center;
    margin-top:50px;
    margin-bottom:50px;
    color:royalblue;
    font-size:1.15rem;
    
    flex-direction:column;

    & > p {
       color:black;
       text-decoration:none!important;
    }
    @media (max-width: 1200px) 
    {
        text-align:center;
    }
`


export const YourLandLordMust = styled.div`
    display:flex;
    flex-direction:column;
    min-height:700px;
    width:100%;
    background-color:royalblue;
    align-items:center;
    justify-content:center;
`