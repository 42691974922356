
import React from "react";
import { Paper, TextField, MenuItem, Select,Button } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import Reaptcha from 'reaptcha';
import { useRef } from "react";

function ContactForm({setThankYou, setLoading, loading, setError, error}) 
{
  const [reported, setReported] = useState('');
  const [landlordType, setLandlordType] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [captcha, setCaptcha] = useState(false); 
  const captchaRef = useRef(null)
  const [captchaError, setCaptchaError] = useState(false);
  

  const handleChangeReported = (event) => 
  {
      setReported(event.target.value);
  };

  const handleSubmitClaim = (e) =>
  {
      e.preventDefault()
     
    //   if(captcha)
    //   {
        setLoading(true)

        axios.post('https://api.hdrclaims.com/claim', 
        {
            "FirstName": firstName,
            "LastName": lastName,
            "Telephone": phoneNumber,
            "Email": email,
            "WasItReported": reported,
            "LandLordType": landlordType
        })
        .then(function (response) 
        { 
            axios.post('https://api.hdrclaims.com/email', 
            {
                "FirstName": firstName,
                "LastName": lastName,
                "Telephone": phoneNumber,
                "Email": email,
                "WasItReported": reported,
                "LandLordType": landlordType
            })
            .then(function (response) 
            {
                //console.log(response);
                setReported('')
                setLandlordType('')
                setPhoneNumber('')
                setEmail('')
                setFirstName('')
                setLastName('')
                setThankYou(true)
                setLoading(false)
                setError(false)
            })
            .catch(function (error) 
            {
                console.log(error);
                setLoading(false);
                setError(true)
            });
        })
        .catch(function (error) 
        {
            console.log(error);
            setLoading(false)
            setError(true)
        });
    //   }
    //   else
    //   {
    //     setCaptchaError(true);
    //   }
      
  }
  const handleChangeLandlordType = (event) => 
  {
      setLandlordType(event.target.value);
  };
  
    const verify = () =>
    {
        captchaRef.current.getResponse().then(res => 
        {
            setCaptcha(true);
        })
    }

  return (
    <>
        <h1 className="thick" style={{marginBottom:'25px', color:'royalblue', marginTop:'50px', textDecoration:'underline', textAlign:'center', filter:'drop-shadow(rgba(0, 0, 0, 0.1) 3px 3px 0px'}}>Get in touch</h1>
        {
        error && (
                <h2 style={{color:'red'}}>Error on our end, please try again later!</h2>
            )
        }
        <p className="getInTouch">Get in touch and we will get back to you as quickly as we can.</p>
        <Paper className="paper" style={{height:'fit-content', marginTop:'50px',marginBottom:'50px', padding:'2rem', textAlign:'center'}} elevation={3}>

        <form onSubmit={(e) => handleSubmitClaim(e)} style={{display:'flex', flexDirection:'column', gap:'25px', textAlign:'center', color:'royalblue', alignItems:'center'}}>
        <h2>Contact us </h2>
        <div style={{display:'flex', gap:'10px', width:'100%'}}>
            <TextField style={{width:'100%'}}  value={firstName} onChange={(e) => setFirstName(e.target.value)} required label='First Name' variant='outlined'/>
            <TextField style={{width:'100%'}} value={lastName}  onChange={(e) => setLastName(e.target.value)} required label="Last Name" variant="outlined"/>
        </div>

        <TextField style={{width:'100%'}} type={'tel'} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required label="Phone Number" variant="outlined"/>
        <TextField style={{width:'100%'}} type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} required label="Email" variant="outlined"/>   

        <label>Was it reported?</label>
        <Select style={{width:'100%'}} required value={reported} onChange={handleChangeReported}>
            <MenuItem key={1} value={true}>Yes</MenuItem>
            <MenuItem key={2} value={false}>No</MenuItem>
        </Select>

        <label>What type of landlord do you have?</label>          
        <Select style={{width:'100%'}} required value={landlordType} onChange={handleChangeLandlordType}>
            <MenuItem key={3} value={'council'}>Council</MenuItem>
            <MenuItem key={4} value={'housing association'}>Housing Association</MenuItem>
            <MenuItem key={5} value={'private'}>Private</MenuItem>
            <MenuItem key={6} value={'not reported'}>Not Reported</MenuItem>
        </Select>
        <Button type="submit" sx={{height:'56px', width:'100%'}} variant="contained">Submit</Button> 
        {/* <ReCAPTCHA
            sitekey="6LdrzVghAAAAANz-j5psuAwbu3eiKGjsO6oif2dX"
            onChange={() => {setCaptcha(true)}}
            ref={captchaRef}
        /> */}

        {
            captchaError && <p style={{color:'red', fontWeight:'800'}}>Please tick the captcha.</p>
        }
        {/* <Reaptcha 
            ref={captchaRef}
            sitekey="6LdrzVghAAAAANz-j5psuAwbu3eiKGjsO6oif2dX"
            onVerify={() => verify()}
        /> */}
        </form>
       
    </Paper>
    </>
  );
}

export default ContactForm;





